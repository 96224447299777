import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import SEO from '../components/Seo';
import PrivateRoute from '../components/auth/protected';
import { getOrdersByUserId } from '../utils/api';
import Layout from '../components/layout';
import OrderList from '../components/orders/OrderList';

const orders = (props) => {
  const [orders, setOrders] = useState(null);
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [showActiveOrder, setShowActiveOrderTabs] = useState(false);

  const fetchOrders = async () => {
    const data = await getOrdersByUserId(props.userErpId, props.token);
    setOrders(
      data && data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
    setOrdersLoaded(true);
  };

  useEffect(() => {
    if (!props.userErpId) {
      navigate('/create');
    }
    fetchOrders();
  }, [props.userErpId]);

  return (
    <>
      <PrivateRoute>
        <SEO title='Orders' />
        <Layout className={'justify-content-end order-status-page'}>
          <Row className='align-items-center' style={{ marginBottom: '0' }}>
            <Col className='title'>
              <h1>Orders</h1>
            </Col>
            <Col className='d-flex justify-content-end'>
              <Link to='/model' className='btn btn-primary'>
                Start New Order
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant={showActiveOrder ? 'link' : 'none'}
                onClick={() => setShowActiveOrderTabs(true)}
              >
                Active
              </Button>
              <Button
                variant={!showActiveOrder ? 'link' : 'none'}
                onClick={() => setShowActiveOrderTabs(false)}
              >
                Order History
              </Button>
            </Col>
          </Row>
          <Row className='page-body'>
            <Col className='text-center px-0'>
              <OrderList
                orders={orders}
                ordersLoaded={ordersLoaded}
                showActive={showActiveOrder}
              />
            </Col>
          </Row>
        </Layout>
      </PrivateRoute>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.login.auth && state.login.auth.profile,
  login: state.login && state.login,
  userErpId: state.login.auth && state.login.auth.profile.erp_id,
  roadmap: state.create.answer && state.create.answer.roadmap,
  answers: state.create.answer && state.create.answer.answers,
  token: state.login.auth && state.login.auth.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  update: (user) => dispatch(updateUser(user)),
  changeMembership: (userID, membership) =>
    dispatch(changeMembership(userID, membership)),
  updateBilling: (userID, token) => dispatch(updateBilling(userID, token)),
  finishEdit: () => dispatch(finishEdit()),
  setRoadmapsToState: (data) => dispatch(setCustomerRoadmap(data)),
  setUserQuestions: (data) => dispatch(setUserChecklist(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(orders);
